<template>
  <div class="w-full">
    <div class="w-full mb-2 grid gap-x-2 grid-cols-1 md:grid-cols-2">
      <select
        v-model="month_attribute"
        class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
      >
        <option
          v-for="(monthElement, monthIndex) in months()"
          :key="monthIndex"
          :value="monthIndex"
          >{{ monthElement }}</option
        >
      </select>
      <select
        v-model="year_attribute"
        class="col-span-2 md:col-span-1 mt-2 md:mt-0 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
      >
        <option
          v-for="(yearElement, yearIndex) in years()"
          :key="yearIndex"
          :value="yearElement"
          >{{ yearElement }}</option
        >
      </select>
    </div>
    <div class="mt-2 w-full">
      <div class="mt-6 grid grid-cols-7 text-sm leading-6 text-gray-500">
        <span>Lu</span>
        <span>Ma</span>
        <span>Mi</span>
        <span>Ju</span>
        <span>Vi</span>
        <span>Sa</span>
        <span>Do</span>
      </div>

      <div
        class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg overflow-hidden bg-gray-200 text-sm shadow ring-1 ring-gray-200"
      >
        <span v-for="empty in dayOfWeek - 1" :key="empty"></span>
        <span
          v-for="(dayElement, dayIndex) in days"
          :key="'day' + dayIndex"
          :class="
            day_attribute === dayElement
              ? 'cursor-pointer bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10'
              : daysAvailable.includes(dayElement)
              ? 'cursor-pointer bg-white py-1.5 text-gray-900 hover:bg-gray-100 focus:z-10'
              : 'cursor-pointer bg-gray-100 py-1.5 text-gray-400 hover:bg-gray-200 focus:z-10'
          "
          @click="
            () => {
              day_attribute = dayElement;
            }
          "
        >
          <span
            :class="
              day_attribute === dayElement
                ? 'mx-auto flex h-7 w-7 items-center justify-center rounded-full bg-green-600 font-semibold text-white'
                : daysAvailable.includes(dayElement)
                ? 'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
                : 'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
            "
            >{{ dayElement }}</span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Calendar',
  props: {
    day: {
      type: Number,
      default: moment().date(),
    },
    month: {
      type: Number,
      default: moment().date(),
    },
    year: {
      type: Number,
      default: moment().date(),
    },
    daysAvailable: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['updateDay', 'updateMonth', 'updateYear'],
  data() {
    return {
      month_attribute: this.month,
      year_attribute: this.year,
      day_attribute: this.day,
    };
  },
  methods: {
    months() {
      moment.locale('es');
      return moment.months();
    },
    years() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - parseInt(back / 2) + i + 1
        );
      };

      return years(10);
    },
  },
  watch: {
    day_attribute(newDay) {
      this.$emit('updateDay', newDay);
    },
    month_attribute(newMonth) {
      this.$emit('updateMonth', newMonth);
    },
    year_attribute(newYear) {
      this.$emit('updateYear', newYear);
    },
  },
  computed: {
    dayOfWeek() {
      return moment(
        this.year_attribute +
          '-' +
          (parseInt(this.month_attribute) + 1) +
          '-01',
        'YYYY-MM-DD'
      ).isoWeekday();
    },
    days() {
      return moment(
        this.year_attribute + '-' + (parseInt(this.month_attribute) + 1),
        'YYYY-MM'
      ).daysInMonth();
    },
  },
};
</script>
